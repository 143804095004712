import React, { useState, useEffect } from "react";
import "./index.css";
import Header from "../../components/Header/header";
import StockCard from "../../components/StockCard/stockcard";
import StockTable from "../../components/StockTable/stocktable";
import Sidebar from "../../components/Sidebar/sidebar";
import logo from "../../assets/images/logo.png"; // Adjust the path accordingly
import { Modal, Input, Form, Select } from "antd";
import { getAllProducts } from "../../api/product";
import { addStock } from '../../api/stock';
import { useNavigate } from "react-router-dom";

import images from '../../assets/images/stock/index.js'
import {useSelector, useDispatch} from 'react-redux';
import { formatDate } from "../../utils/datetime";
import { connect } from 'react-redux';


const { Option } = Select;

const Stock = ({value}) => {
  const [isModalMomentsVisible, setIsModalMomentsVisible] = useState(false);
  const [isModalBoxVisible, setIsModalBoxVisible] = useState(false);
  const [formDataMoments, setFormDataMoments] = useState({
    product_id: "",
    quantity: 0,
  });
  const [formDataBox, setFormDataBox] = useState({
    product_id: "",
    quantity: 0,
  });


  const [stockPoonyaMoments, setStockPoonyaMoments] = useState([])
  const [stockPoonyaBox, setStockPoonyaBox] = useState([])
  const [productMomentsOptions, setProductMomentsOptions] = useState([])
  const [productBoxOptions, setProductBoxOptions] = useState([])

  const { Option } = Select;

  const navigate = useNavigate();

  //Parsing from another screen/page
  // const {user_data, transaction_number, branch_code} = route.param


  //Get Value from Redux
  const loginReducer = useSelector(state => state.loginReducer);

  // console.log("login reducer", loginReducer)

  useEffect(() => {
    getAllProducts().then(res => {
      let empArr1 = [];
      let empArr2 = [];
      let empArr3 = [];
      let empArr4 = [];
      for(let i=0; i<res.length;i++){
        if(res[i].product_type === 'poonya-moments'){
          empArr1.push(
            {
              title: res[i].product_name,
              quantity: res[i].quantity,
              lastUpdate: res[i].updatedAt,
              logo: !!images[res[i].product_name.replace(/\s/g, '')] ? images[res[i].product_name.replace(/\s/g, '')] : images['DefaultImage']
          })
          empArr3.push({
            label : res[i].product_name,
            value: res[i].product_id
          })
        }
        else if(res[i].product_type === 'poonya-box'){
          empArr2.push(
            {
              title: res[i].product_name,
              quantity: res[i].quantity,
              lastUpdate: res[i].updatedAt,
              logo: images[res[i].product_name.replace(/\s/g, '')] 
          })
          empArr4.push({
            label : res[i].product_name,
            value: res[i].product_id
          })
        }
      }
      setStockPoonyaMoments(empArr1)
      setStockPoonyaBox(empArr2)
      setProductMomentsOptions(empArr3)
      setProductBoxOptions(empArr4)
    })

  }, []);

  const showModalMoments = () => {
    setIsModalMomentsVisible(true);
  };

  const handleOkMoments = async () => {
    // Handle form submission for Poonya Moments
    // navigate('/stock')
    await addStock(formDataMoments).then(res => {
      // alert("Add Stock Success")
      if(window.confirm("Add Stock Success")) {
        window.location.href = "https://logistic.studiopoonya.net/stock"
      }
    }
    ).catch(err => console.log(err))
    setIsModalMomentsVisible(false);
  };

  const handleCancelMoments = () => {
    setIsModalMomentsVisible(false);
  };

  const handleChangeMoments = (e) => {
    const { name, value } = e.target;
    setFormDataMoments({
      ...formDataMoments,
      [name]: value,
    });
  };

  const handleSelectChangeProductMoments = (value) => {
    setFormDataMoments({
      ...formDataMoments,
      product_id : value,
    });
  };


  const showModalBox = () => {
    setIsModalBoxVisible(true);
  };

  // const handleOkBox = async () => {
  //   // Handle form submission for Poonya Box
  //   await addStock(formDataBox).then(res => {
  //     navigate(0)
  //   }).catch(err => console.log(err))
  //   setIsModalBoxVisible(false);
  // };

  const handleOkBox = async () => {
  // Handle form submission for Poonya Box
  await addStock(formDataBox)
    .then(res => {
      // Navigate to the desired URL after form submission
      window.location.href = "https://logistic.studiopoonya.net/stock"; // Redirect to the URL
    })
    .catch(err => console.log(err));
  
  setIsModalBoxVisible(false);
};


  const handleCancelBox = () => {
    setIsModalBoxVisible(false);
  };

  const handleChangeBox = (e) => {
    const { name, value } = e.target;
    setFormDataBox({
      ...formDataBox,
      [name]: value,
    });
  };

  
  const handleSelectChangeProductBox = (value) => {
    setFormDataBox({
      ...formDataBox,
      product_id : value,
    });
  };

  return (
    <div className="app-container">
      <Sidebar />
      <div className="content">
        <Header />
        <div className="header-row">
          <h2>Poonya Moments</h2>
          <button
            className="tambah-stock-button-moments"
            onClick={showModalMoments}
          >
            Tambah Stock
          </button>
        </div>
        <div className="card-grid">
          {stockPoonyaMoments.slice().map((item, index) => (
            <StockCard
              key={index}
              title={item.title}
              quantity={item.quantity}
              lastUpdate={formatDate(item.lastUpdate)}
              logo={item.logo}
              large={true}
            />
          ))}
        </div>
        <div className="header-row">
          <h2>Poonya Box</h2>
          <button className="tambah-stock-button-box" onClick={showModalBox}>
            Tambah Stock
          </button>
        </div>
        <div className="card-grid">
          {stockPoonyaBox.map((item, index) => (
            <StockCard
              key={index}
              title={item.title}
              quantity={item.quantity}
              lastUpdate={item.lastUpdate}
              logo={item.logo}
              large={true}
            />
          ))}
        </div>
        <br />
        {/* <StockTable /> */}
      </div>

      {/* Modal for Tambah Stock Poonya Moments */}
      <Modal
        title="Tambah Stock Poonya Moments"
        visible={isModalMomentsVisible}
        onOk={handleOkMoments}
        onCancel={handleCancelMoments}
      >
        <Form>
        <Form.Item label="Product">
            <Select
              value={formDataMoments.product_id}
              onChange={handleSelectChangeProductMoments}
              placeholder="Product"
            >
               {productMomentsOptions.map((item, index) => (
              <Option value={item.value} key={item.value}>{item.label}</Option>
          ))}
            </Select>
          </Form.Item>
          <Form.Item label="Quantity">
            <Input
              name="quantity"
              type="number"
              value={formDataMoments.quantity}
              onChange={handleChangeMoments}
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* Modal for Tambah Stock Poonya Box */}
      <Modal
        title="Tambah Stock Poonya Box"
        visible={isModalBoxVisible}
        onOk={handleOkBox}
        onCancel={handleCancelBox}
      >
        <Form>
        <Form.Item label="Product">
            <Select
              value={formDataBox.product_id}
              onChange={handleSelectChangeProductBox}
              placeholder="Product"
            >
               {productBoxOptions.map((item, index) => (
              <Option value={item.value} key={item.value}>{item.label}</Option>
          ))}
            </Select>
          </Form.Item>
          <Form.Item label="Quantity">
            <Input
              name="quantity"
              type="number"
              value={formDataBox.quantity}
              onChange={handleChangeBox}
            />
          </Form.Item>
         
        </Form>
      </Modal>
    </div>
  );
};

export default Stock;
