import React, { useEffect, useState } from "react";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { getAllProducts } from "../../api/product";
import "./producttable.css";
import { formatDate } from "../../utils/datetime";

const ProductTable = () => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const [dataProducts, setDataProducts] = useState([]);

  useEffect(() => {
    getAllProducts().then(res => {
      let empArr = [];
      for(let i=0; i<res.length; i++) {
          empArr.push({
            no: i+1,
            name: res[i].product_name,
            type: res[i].product_type === 'poonya-moments' ? "Poonya Moments" : "Poonya Box",
            quantity: res[i].quantity,
            date: res[i].updatedAt,
            account: res[i].updatedBy !== '' ? res[i].updatedBy : res[i].createdBy,
          });
      }
      setDataProducts(empArr);
    });
  }, []);

  const handleDelete = (no) => {
    const updatedData = dataProducts.filter((item) => item.no !== no);
    setDataProducts(updatedData);
  };

  const sortData = (data, key, direction) => {
    return data.sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = sortConfig.key
    ? sortData(dataProducts, sortConfig.key, sortConfig.direction)
    : dataProducts;

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? <BsArrowUp /> : <BsArrowDown />;
    }
    return null;
  };

  return (
    <div className="table-container">
      <table className="sortable-table">
        <thead>
          <tr>
            <th onClick={() => handleSort("no")}>No {getSortIcon("no")}</th>
            <th onClick={() => handleSort("name")}>Name Items {getSortIcon("name")}</th>
            <th onClick={() => handleSort("type")}>Type {getSortIcon("type")}</th>
            <th onClick={() => handleSort("quantity")}>Quantity {getSortIcon("quantity")}</th>
            <th onClick={() => handleSort("date")}>Date {getSortIcon("date")}</th>
            <th onClick={() => handleSort("account")}>Account {getSortIcon("account")}</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row) => (
            <tr key={row.no}>
              <td>{row.no}</td>
              <td>{row.name}</td>
              <td>{row.type}</td>
              <td>{row.quantity}</td>
              <td>{formatDate(row.date)}</td>
              <td>{row.account}</td>
              <td>
                <button
                  style={{
                    backgroundColor: "#e74c3c",
                    color: "white",
                    border: "none",
                    padding: "5px 10px",
                    borderRadius: "4px",
                    cursor: "pointer",
                    transition: "background-color 0.3s"
                  }}
                  onClick={() => handleDelete(row.no)}
                  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#c0392b"}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#e74c3c"}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductTable;
